import { LoadLayoutFieldsTypesType } from "./configs";

export type AddressType = {
  zipCode: string;
  address: string;
  number: number;
  neighborhood: string;
  complement: string;
  country: string;
  state: string;
  city: string;
};

export type BlacklistContentType = {
  Key: string;
  LastModified: string;
  ETag: string;
  Size: number;
  StorageClass: string;
  Owner: {
    ID: string;
  };
};

export type BotBoteriaType = {
  _id: string;
  title: string;
  description: string;
  creationDate: string;
};

export type BotTypes = {
  active: boolean;
  botId: string;
  name: string;
  type: string;
  value: string;
  __v: number;
  _id: string;
};

export type BusinessHourType = {
  schedules: ScheduleType[];
  weekDays: number[];
  DDDs?: OptionType<number>[];
};

export type DenyListType = {
  useDenyList: boolean;
  denyLists?: OptionType<string>[];
}

export type CategoryType = {
  id: string;
  name: string;
};

export enum ChannelsEnum {
  sms = "SMS",
  hsm = "HSM",
  email = "Email",
  api = "API",
}

export type ClaimType = {
  _id: string;
  profileId: string;
  name: string;
  companyId: string;
};

export type ConnectorType = {
  voiceSettings: VoiceSettingsType;
  version: number;
  _id: string;
  from: string;
  source: string;
  to: string;
  target: string;
  botId: string;
  __v: number;
};

export type ConsumptionPlanType = {
  day?: number;
  sms: {
    event_operation_in: number;
    event_operation_out: number;
    event_provider: string;
    event_type: ChannelsEnum;
    total: number;
  };
  hsm: {
    event_operation_in: number;
    event_operation_out: number;
    event_provider: string;
    event_type: ChannelsEnum;
    total: number;
  };
  email: {
    event_operation_in: number;
    event_operation_out: number;
    event_provider: string;
    event_type: ChannelsEnum;
    total: number;
  };
  api: {
    event_operation_in: number;
    event_operation_out: number;
    event_provider: string;
    event_type: ChannelsEnum;
    total: number;
  };
  boteria: {
    event_operation_in: number;
    event_operation_out: number;
    event_provider: string;
    event_type: ChannelsEnum;
    total: number;
  };
  year: number;
  month: number;
  total: {
    in: number;
    out: number;
    count: number;
  };
};

export type ConsumptionType = {
  webchat: number;
  whatsapp: number;
  messenger: number;
};

export type ContractDownloadType = "application/json" | "text/csv";

export type CustomExportType = {
  status: "done" | "error" | "pending" | "processing";
  created_at: Date;
  updated_at: Date;
  started_at: Date;
  finished_at: Date;
  schedule_to: Date;
  active: boolean;
  _id: string;
  id_flow: string;
  id_customize_report: string;
  id_user: string;
  username: string;
  filter_data: {
    loadsSelecteds: OptionType<string>[];
    exportFieldsSelecteds: OptionType<string>[];
  };
  export_name: string;
  file_path_to_download: string;
  __v: number;
};

export type CustomTemplateType = {
  _id: string;
  botId: string;
  name: string;
  description: string;
  listFields: OptionType<string>[];
  hasTagsIncluded: boolean;
  __v: number;
};

export type DataPlanConsumptionType = {
  consumption: ConsumptionType;
  usedAdditionalMessage: string[];
  _id: string;
  currentMonth: string;
};

export type CreditPlanType = {
  _id: string;
  description: string;
  credit: number;
  price: number;
}

export type DDDTotalType = {
  ddd: number;
  total: number;
  _id: string;
};

export type EmailProvider = "sendfy";

export type FilterDateType = {
  startDate: Date;
  endDate: Date;
};

export type FilterDateTypeWithCompany = {
  startDate: Date;
  endDate: Date;
  companyId: string;
  organizationId: string;
};

export type GlobalTypes = {
  active: true;
  name: string;
  type: string;
  variableSensitivity: string;
  __v: number;
  _id: string;
};

export type HTMLInputDateTimeType =
  | "date"
  | "datetime-local"
  | "month"
  | "time";

export type ItemIDType = {
  version: number;
  id: string;
};

export type JourneyTemplateType = {
  _id?: string;
  botId?: string;
  userId?: string;
  title: string;
  banner?: string;
  description: string;
  published: boolean;
  when?: Date;
  dateUpdate?: string;
};

export type KeyValueType<T> = {
  key: T;
  value: string;
};

export type ListConsumptionsType = "month" | "day";

export enum LoadActionEnum {
  Start = "start",
  Pause = "pause",
  Finish = "finish",
}

export enum LoadActivationEnum {
  Running = "running",
  Paused = "paused",
  Stopped = "stopped",
  Concluded = "concluded",
}

export enum LoadStatusEnum {
  Done = "done",
  Error = "error",
  Pending = "pending",
  Processing = "processing",
}

export type LoadTotalsType = {
  etag: string;
  contacts: number;
  phones: number;
  emails: number;
  load: string;
};

export type LoadType = {
  active: boolean;
  api_key: string;
  load_name: string;
  created_at: Date;
  upload_response: {
    file_etag: string;
    key_path: string;
    processed: boolean;
    uploaded_at: Date;
  }[];
  id_flow: string;
  schedule: Date;
  finish_schedule: Date;
  register: Date;
  sending_mode: string;
  status: LoadStatusEnum;
  updated_at: Date;
  __v: number;
  _id: string;
  activation_status: LoadActivationEnum;
  periodicity?: PeriodicityEnum;
  delivery_message_time?: string;
  source: 'group' | 'file',
  loadLayoutId: {
    type: String,
    required: true,
  },
  id_group: {
    type: String,
  },
  total_contacts?: number,
  valid_contacts?: number,
  total_phones?: number,
  total_emails?: number,
  total_DDDs?: {
    ddd: number,
    total: number,
  }[],
  batchesConfig?: {
    contactsPerBatch: number,
    interval: number,
    intervalType: string,
  }
  batches?: {
    _id?: string,
    name: string,
    contactCount: number,
    scheduleDate: Date,
    status: string,
  }[]
};

export type LocationStateType = {
  from: {
    pathname: string;
    search: string;
    hash: string;
    key: string;
  };
};

export type OptionListType = {
  _id: string;
  __v: number;
  active: boolean;
  isOptOut: boolean;
  isOptIn: boolean;
  botId: string;
  type: "user" | "global";
  name: string;
  acceptedValues: { _id: string; value: string }[];
};

export type OptionType<T> = {
  _id?: string;
  value: T;
  label: string;
};

export type OrganizationType = {
  removed: boolean;
  _id: string;
  name: string;
  companyId: string;
  code_service?: string;
  code_project?: string;
  dateCreation: string;
  dateUpdate: string;
  credit?: number;
  plan?: {
    type: 'pre' | 'pos';
    balance?: number;
  };
  __v: number;
};

export type OwnerType = {
  name: string;
  email: string;
  phoneNumber: string;
};

export enum PeriodicityEnum {
  diary = "daily",
  onlyOnce = "one_time",
}

export type PlatformType = {
  type: string;
  token: string;
  platform: string;
  isActive: boolean;
};

export type ProfileType = {
  _id: string;
  _v: number;
  name: string;
  description: string;
};

export type ReportType = {
  _id: string;
  status: "pending" | "ok" | "error";
  createdAt: string;
  generatedAt: string;
  filename: string;
  name: string;
  fileFormat: string;
  sessionId: string;
};

export type ScheduleType = {
  from: string;
  to: string;
};

export type SearchFieldType = {
  label: string;
  key: string;
};

export type StepItemType = {
  _id: string;
  title: string;
  children: JSX.Element;
  showWhenType?: string[];
  to: string;
};

export type TagReportType = { tag: string; count: number };

export type TagType = {
  _id: string;
  active: boolean;
  botId: string;
  name: string;
  type: "global" | "user";
  __v: number;
};

export type TfaType = {
  active: boolean;
  secret: string;
  dataURL: string;
};

export type TimezoneType = {
  fuso: string;
  fusoName: string;
};

export type TotalPerChannelType = {
  sms: {
    count: number;
    in: number;
    out: number;
  };
  hsm: {
    count: number;
    in: number;
    out: number;
  };
  email: {
    count: number;
    in: number;
    out: number;
  };
  api: {
    count: number;
    in: number;
    out: number;
  };
  boteria: {
    count: number;
    in: number;
    out: number;
  };
};

export type TotalsType = {
  totalContacts: number;
  totalEmails: number;
  totalPhones: number;
  totalsByDDD: DDDTotalType[];
  totalsByLoad: LoadTotalsType[];
};

export type ValueTypes = {
  _id: string;
  value: string;
};

export type VariableType = {
  _id: string;
  active: boolean;
  variableSensitivity: "DEFAULT" | "NOT_STORAGE" | "ENCRYPTED_DATA";
  botId?: string;
  type: "global" | "user" | "contact";
  dataType: LoadLayoutFieldsTypesType;
  name: string;
  value?: string;
  __v: number;
};

export type VersionType = {
  _id: string;
  id: number;
  createDate: string;
  name: string;
  description: string;
};

export type VoiceSettingsType = {
  idProject: number;
  codeUser: number;
  idIvrFlowDynamic: number;
  idIvrFlowDynamicGroup: number;
  idIvrStepStart: number;
  idIvrStepFlow: number;
  idIvrStep: number;
  mustSyncApiExternal: boolean;
  idIvrVoice: number;
  isActive: boolean;
};

export enum WhenEnum {
  current = "current",
  toSchedule = "toSchedule",
}

export type BatchIntervalType = {
  id: number;
  name: 'minutes' | 'hours'
};