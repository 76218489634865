import { AxiosRequestConfig } from "axios";
import {
  AttendanceScheduleType,
  ChangeVersionJourneyRequest,
  ChangeVersionJourneyResponse,
  ConnectorType,
  ItemFlowType,
  JourneyType,
  OptionType,
  PublishJourneyRequest,
  ReportFlowType,
  DenyListSettings
} from "models";
import service from ".";

export const getJourneys = async (config?: AxiosRequestConfig) =>
  await service.get<JourneyType[]>("v1/bots", config);

export const getJourneyBySector = async (
  sectorId: string,
  config?: AxiosRequestConfig
) => {
  return await service.get<JourneyType[]>(`v1/bots/${sectorId}`, config);
};

export const getJourney = async (
  journeyId: string,
  config?: AxiosRequestConfig
) => await service.get<JourneyType>(`v1/bots/${journeyId}`, config);

export const addJourney = async (
  params: FormData,
  config?: AxiosRequestConfig
) => await service.post<JourneyType>(`v1/orquestrador`, params, config);

export const addSingleSend = async (
  params: FormData,
  config?: AxiosRequestConfig
) => await service.post<{singleSendData: JourneyType; itemId: string; mediaUploadError?: string}>(`v1/orquestrador/singleSend`, params, config);
export const updateSingleSendSettings = async (
  singleSendId: string,
  params: any,
  config?: AxiosRequestConfig
) => await service.put<JourneyType>(`v1/orquestrador/singleSend/${singleSendId}/settings`, params, config);

export const updateJourney = async (
  journeyId: string,
  params: JourneyType,
  config?: AxiosRequestConfig
) =>
  await service.put<JourneyType>(`v1/bots/update/${journeyId}`, params, config);

export const publishJourney = async (
  journeyId: string,
  params: PublishJourneyRequest,
  config?: AxiosRequestConfig
) =>
  await service.post<JourneyType>(
    `v1/bots/${journeyId}/publish`,
    params,
    config
  );

export const changeVersion = async (
  journeyId: string,
  params: ChangeVersionJourneyRequest,
  config?: AxiosRequestConfig
) =>
  await service.put<ChangeVersionJourneyResponse>(
    `v1/bots/${journeyId}/stagingArea`,
    params,
    config
  );

export const removeJourney = async (
  journeyId: string,
  config?: AxiosRequestConfig
) => await service.delete(`v1/bots/${journeyId}`, config);

export const getItems = async (
  journeyId: string,
  config?: AxiosRequestConfig
) =>
  await service.get<{ items: ItemFlowType[]; bot: JourneyType }>(
    `v1/bots/${journeyId}/items`,
    config
  );

export const addItem = async (
  journeyId: string,
  params: ItemFlowType,
  config?: AxiosRequestConfig
) =>
  await service.post<ItemFlowType>(
    `v1/bots/${journeyId}/items`,
    params,
    config
  );

export const updateItem = async (
  journeyId: string,
  itemId: string,
  params: ItemFlowType,
  config?: AxiosRequestConfig
) =>
  await service.put<ItemFlowType>(
    `v1/bots/${journeyId}/items/${itemId}`,
    params,
    config
  );

export const uploadItemFile = async (
  journeyId: string,
  itemId: string,
  params: FormData,
  config?: AxiosRequestConfig
) =>
  await service.put<ItemFlowType>(
    `v1/bots/${journeyId}/items/${itemId}/upload`,
    params,
    config
  );

export const removeItemFile = async (
  journeyId: string,
  itemId: string,
  params?: Object,
  config?: AxiosRequestConfig
) =>
  await service.put<ItemFlowType>(
    `v1/bots/${journeyId}/items/${itemId}/remove`,
    params
  );

export const removeItem = async (
  journeyId: string,
  itemId: string,
  config?: AxiosRequestConfig
) =>
  await service.delete<ItemFlowType>(
    `v1/bots/${journeyId}/items/${itemId}`,
    config
  );

export const getConnectors = async (
  journeyId: string,
  config?: AxiosRequestConfig
) =>
  await service.get<{ connectors: ConnectorType[] }>(
    `v1/bots/${journeyId}/connectors`,
    config
  );

export const addConnectors = async (
  journeyId: string,
  params: ConnectorType,
  config?: AxiosRequestConfig
) =>
  await service.post<ConnectorType[]>(
    `v1/bots/${journeyId}/connectors`,
    params,
    config
  );

export const removeConnector = async (
  journeyId: string,
  connectorId: string,
  config?: AxiosRequestConfig
) =>
  await service.delete<ConnectorType>(
    `v1/bots/${journeyId}/connectors/${connectorId}`,
    config
  );

export const updateIdentityJourney = async (
  journeyId: string,
  params: FormData,
  config?: AxiosRequestConfig
) =>
  await service.put<JourneyType>(
    `v1/bots/update-general/${journeyId}`,
    params,
    config
  );

export const updateSchedules = async (
  journeyId: string,  
  params: AttendanceScheduleType,
  loadRunningDays?: string,
  denyList?: DenyListSettings,
  config?: AxiosRequestConfig
) =>
  await service.post<JourneyType>(
    `v1/orquestrador/attendance-schedule-settings/${journeyId}`,
    { 
      settings: params,
      loadRunningDays,
      denyList,
    },
    config
  );

export const getFlowByVersion = async (
  journeyId: string,
  version_flow: number,
  config?: AxiosRequestConfig
) =>
  await service.get<ReportFlowType>(`v1/bots/${journeyId}/full`, {
    params: { version_flow },
    ...config,
  });

export const getEscapeStatus = async (config?: AxiosRequestConfig) =>
  await service.get<OptionType<string>[]>(
    "v1/orquestrador/providersStatus",
    config
  );

export const addContactsGroupLoad = async (params: any, config?: AxiosRequestConfig) =>
  await service.post<JourneyType>(
    `v1/orquestrador/contacts/groupsend`,
    params,
    config
  );  