import Layout from "components/layout";
import Loading from "components/loading";
import NotFound from "components/notFound";
import { lazy, Suspense } from "react";
import { Route, Routes } from "react-router-dom";
import RequireAuth from "./requireAuth";

const AuthPage = lazy(() => import("pages/auth"));
const DashboardPage = lazy(() => import("pages/dashboard"));
const ProfilePage = lazy(() => import("pages/profile"));
const PlanPage = lazy(() => import("pages/plan"));
const JouneyPage = lazy(() => import("pages/journey"));
const CompanyPage = lazy(() => import("pages/company"));
const ReportPage = lazy(() => import("pages/report"));
const ConfigPage = lazy(() => import("pages/config"));
const ExportPage = lazy(() => import("pages/export"));
const ContactFlowPage = lazy(
  () => import("pages/report/subpages/contactsFlow")
);

const RoutesMap = () => {
  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        <Route path="authentication/*" element={<AuthPage />} />
        <Route path="/" element={<RequireAuth children={<Layout />} />}>
          <Route index element={<DashboardPage />} />
          <Route path="profile">
            <Route index element={<ProfilePage />} />
            <Route path=":step" element={<ProfilePage />} />
          </Route>
          <Route path="company">
            <Route index element={<CompanyPage />} />
            <Route path=":step" element={<CompanyPage />} />
          </Route>
          <Route path="plan/*" element={<PlanPage />} />
          <Route path="journey/*" element={<JouneyPage />} />
          <Route path="config">
            <Route index element={<ConfigPage />} />
            <Route path=":step" element={<ConfigPage />} />
          </Route>
          <Route path="report">
            <Route index element={<ReportPage />} />
            <Route path=":step" element={<ReportPage />} />
            <Route path="contact-flow" element={<ContactFlowPage />} />
            {/* <Route path=":id">
              <Route index element={<ReportPage />} />
              <Route path="contact-flow" element={<ContactFlowPage />} />
              <Route path=":step" element={<ReportPage />} />
            </Route> */}
          </Route>
          <Route path="export">
            <Route index element={<ExportPage />} />
            <Route path=":id">
              <Route index element={<ExportPage />} />
              <Route path=":step" element={<ExportPage />} />
            </Route>
          </Route>
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Suspense>
  );
};

export default RoutesMap;
