import { AxiosRequestConfig } from "axios";
import {
  ActivateAccountRequest,
  AuthrnticationTokenRequest,
  CreatePasswordRequest,
  NewPasswordRequest,
  RecoverEmailRequest,
  RecoverPasswordRequest,
  SignInRequest,
  SignInResponse,
  SignUpRequest,
  SignUpResponse,
} from "models";
import service from ".";

export const signIn = async (
  params: SignInRequest,
  config?: AxiosRequestConfig
) => await service.post<SignInResponse>("v1/auth/sign-in", params, config);

export const authenticationToken = async (
  params: AuthrnticationTokenRequest,
  config?: AxiosRequestConfig
) =>
  await service.post<SignInResponse>("v1/auth/cognito-sign-in ", params, config);

export const signUp = async (
  params: SignUpRequest,
  config?: AxiosRequestConfig
) => await service.post<SignUpResponse>("v1/auth/sign-up", params, config);

export const signUpThirdParty = async (
  partnerId: string,
  params: SignUpRequest,
  config?: AxiosRequestConfig
) => await service.post<SignUpResponse>(`v1/auth/sign-up-third-party/${partnerId}`, params, config);

export const resendEmail = async (email: string, config?: AxiosRequestConfig) =>
  await service.get("v1/auth/resend-email-confirmation", {
    ...config,
    params: { email },
  });

export const recoverPassword = async (
  params: RecoverPasswordRequest,
  config?: AxiosRequestConfig
) => await service.post("v1/auth/recover-password", params, config);

export const recoverEmail = async (
  params: RecoverEmailRequest,
  config?: AxiosRequestConfig
) => await service.post("v1/auth/recover-email", params, config);

export const activateAccount = async (
  params: ActivateAccountRequest,
  config?: AxiosRequestConfig
) => await service.post("v1/auth/confirm", params, config);

export const newPassword = async (
  params: NewPasswordRequest,
  config?: AxiosRequestConfig
) => await service.patch("v1/auth/reset-password", params, config);

export const createPassword = async (
  token: string,
  params: CreatePasswordRequest,
  config?: AxiosRequestConfig
) => await service.post(`/v1/password/create/${token}`, params, config);

