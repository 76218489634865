import { AxiosRequestConfig } from "axios";
import { CompanyType, OrganizationType, VtexIntegrationType } from "models";
import service from ".";

export const getCompanies = async (
  userId: string,
  config?: AxiosRequestConfig
) =>
  await service.get<CompanyType[]>("v1/companies", {
    ...config,
    params: { userId },
  });

export const getCompany = async (
  companyId: string,
  config?: AxiosRequestConfig
) =>
  await service.get<CompanyType>(`v1/companies/${companyId}?key=kgjdhURyashsJKSkd2kkd98Yf7`, {
    ...config,
  });

export const getOrganizations = async (userId: string, config?: AxiosRequestConfig) =>
  await service.get<OrganizationType[]>("v1/companies/organization", {
    ...config,
    params: { userId },
  });

export const getOrganizationsNew = async (
  companyId: string,
  config?: AxiosRequestConfig
) =>
  await service.get<OrganizationType[]>(
    `v1/organizations/list/${companyId}`,
    config
  );

export const getOrganization = async (
  organizationId: string,
  config?: AxiosRequestConfig
) =>
  await service.get<OrganizationType[]>(
    `v1/organizations/find/${organizationId}`,
    config
  );

export const postOrganizations = async (
  companyId: string,
  params: OrganizationType,
  config?: AxiosRequestConfig
) =>
  await service.post<OrganizationType[]>(
    `v1/organizations/create/${companyId}`,
    params,
    config
  );

export const updateCompany = async (
  companyId: string,
  params: Partial<CompanyType>,
  config?: AxiosRequestConfig
) => {
  return await service.put<CompanyType>(`v1/companies/${companyId}`, {
    ...params,
    ...config,
  });
}

export const configureIntegrationVtex = async (
  companyId: string,
  params: Partial<VtexIntegrationType>,
  config?: AxiosRequestConfig
) => {
  return await service.post<VtexIntegrationType>(`/v1/companies/${companyId}/integrations/vtex`, {
    ...params,
    ...config,
  });
}

export const updateOrganizations = async (
  companyId: string,
  params: OrganizationType,
  config?: AxiosRequestConfig
) =>
  await service.put<OrganizationType>(
    `v1/organizations/update/${companyId}`,
    params,
    config
  );

  export const addCreditToOrganization = async (
  organizationId: string,
  params: { credit: number, description: string },
  config?: AxiosRequestConfig
) =>
  await service.post<OrganizationType>(
    `v1/organizations/credit/${organizationId}`,
    params,
    config
  );

export const deleteOrganizations = async (_id: string) =>
  await service.delete<OrganizationType>(`v1/organizations/delete/${_id}`);
