import { LoadLayoutFieldsTypesType, OptionType, PeriodicityEnum } from "models";

interface iConditionalsOptions extends OptionType<string> {
  types?: LoadLayoutFieldsTypesType[];
}

export const affirmativeField = "{system.urlEmailAffirmativeReponse}";

export const conditionalsOptions: iConditionalsOptions[] = [
  {
    value: ">",
    label: "conditionals.greaterThan",
    types: ["number", "date"],
  },
  {
    value: "<",
    label: "conditionals.lessThan",
    types: ["number", "date"],
  },
  {
    value: ">=",
    label: "conditionals.greaterThanOrEqualTo",
    types: ["number", "date"],
  },
  {
    value: "<=",
    label: "conditionals.lessThanOrEqualTo",
    types: ["number", "date"],
  },
  {
    value: "=",
    label: "conditionals.equalTo",
    types: ["custom", "number", "date", "text"],
  },
  {
    value: "!=",
    label: "conditionals.notEqualTo",
    types: ["custom", "number", "date", "text"],
  },
  {
    value: "contains",
    label: "conditionals.contains",
    types: ["text", "custom"],
  },
  {
    value: "not_contains",
    label: "conditionals.notContains",
    types: ["text", "custom"],
  },
  {
    value: "contained",
    label: "conditionals.contained",
    types: ["text", "custom"],
  },
  {
    value: "not_contained",
    label: "conditionals.notContained",
    types: ["text", "custom"],
  },
];

export const loadLayoutsOptions: OptionType<string>[] = [
  {
    value: "identifier",
    label: "labels.identifier",
  },
  {
    value: "phone",
    label: "labels.phone",
  },
  {
    value: "email",
    label: "labels.email",
  },
  {
    value: "date",
    label: "labels.date",
  },
  {
    value: "number",
    label: "labels.number",
  },
  {
    value: "text",
    label: "labels.text",
  },
  {
    value: "custom",
    label: "custom",
  },
  {
    value: "name",
    label: "labels.name",
  },
];

export const emptyValue = "empty";

export const i18nLanguage = "i18nextLng";

export const itemsPerPaginations: number[] = [4, 8, 12, 16, 20];

export const genresOptions: OptionType<string>[] = [
  {
    value: "m",
    label: "genres.masculine",
  },
  {
    value: "f",
    label: "genres.feminine",
  },
  {
    value: "n",
    label: "genres.notDeclare",
  },
];

export const joditEditorButtons =
  "bold,italic,underline,strikethrough,|,eraser,ul,ol,|,font,fontsize,brush,paragraph,classSpan,lineHeight,superscript,subscript,|,image,video,file,|,spellcheck,|,cut,copy,paste,selectall,hr,table,link,|,indent,outdent,justify,left,center,right,|,undo,redo,";

export const journeyTypesOptions: OptionType<string>[] = [
  {
    value: "orquestrador",
    label: "journeyType.orchestrator",
  },
];

export const languages = ["pt", "en"];

export const loadPeriodsOptions: OptionType<PeriodicityEnum>[] = [
  {
    value: PeriodicityEnum.diary,
    label: "labels.diary",
  },
  {
    value: PeriodicityEnum.onlyOnce,
    label: "labels.onlyOnce",
  },
];

export const sendingModesOptions: OptionType<string>[] = [
  {
    value: "vertical",
    label: "Vertical",
  },
  {
    value: "horizontal",
    label: "Horizontal",
  },
];

export const negativeField = "{system.urlEmailNegativeReponse}";

export const OPERATORS = {
  equal: "==",
  not_equal: "!==",
  greater_than: ">",
  greater_than_or_equal: ">=",
  less_than: "<",
  less_than_or_equal: "<=",
  includes: "contain",
  not_includes: "not contain",
  included: "⊂",
  not_included: "⊈",
};
