import { validPassword, validPhone, validZipCode } from "consts";
import { PeriodicityEnum, WhenEnum } from "models";
import * as yup from "yup";

// Defaults

const DateSchema = yup
  .date()
  .nullable()
  .transform((value, origin) => (origin === "" ? undefined : value));

const NumberArraySchema = yup.array().of(yup.number());

const NumberArrayRequiredSchema = NumberArraySchema.required(
  "errors.required"
).min(1, "errors.required");

const OptionalSchema = yup.string().optional();

const RequiredSchema = yup.string().required("errors.required");

const StringArraySchema = yup.array().of(yup.string());

const StringArrayRequiredSchema = StringArraySchema.required(
  "errors.required"
).min(1, "errors.required");

// Compounds

const AddressSchema = yup.object().shape({
  zipCode: yup
    .string()
    .matches(validZipCode, {
      message: "errors.invalidZipCode",
      excludeEmptyString: true,
    })
    .optional(),
  address: OptionalSchema,
  number: OptionalSchema,
  country: OptionalSchema,
  state: OptionalSchema,
  city: OptionalSchema,
});

const BusinessSchema = yup
  .array()
  .of(
    yup.object().shape({
      days: NumberArrayRequiredSchema,
      from: RequiredSchema,
      to: RequiredSchema,
      ddds: NumberArraySchema,
    })
  )
  .required("errors.required")
  .min(1, "errors.required");

const EmailRequiredSchema = yup
  .string()
  .required("errors.required")
  .email("errors.invalidEmail");

const EmailSchema = yup.string().email("errors.invalidEmail");

const PasswordSchema = yup
  .string()
  .required("errors.required")
  .matches(validPassword, "errors.invalidPassword");

const PhoneSchema = yup
  .string()
  .required("errors.required")
  .matches(validPhone, "errors.invalidPhone");

const SendgridSchema = yup
  .object({
    username: EmailRequiredSchema,
    idApiKey: RequiredSchema,
    apiKey: RequiredSchema,
  })
  .notRequired()
  .default(undefined);

// Exports

export const AddJourneySchema = yup.object().shape({
  title: RequiredSchema,
  description: RequiredSchema,
  templateJourneyId: OptionalSchema,
});

export const AddLoadSchema = yup.object().shape({
  loadName: RequiredSchema,
  period: OptionalSchema,
  when: RequiredSchema,
  schedule: OptionalSchema.when(["period", "when"], (period, when) =>
    period === PeriodicityEnum.diary || when === WhenEnum.toSchedule
      ? RequiredSchema
      : OptionalSchema
  ),
  runTime: OptionalSchema.when("period", {
    is: PeriodicityEnum.diary,
    then: RequiredSchema,
  }),
  finishSchedule: DateSchema.when("schedule", (schedule, schema) =>
    schedule ? schema.min(new Date(schedule), "errors.invalidDate") : schema
  ),
  sendInBatches: yup.boolean().required(),
  contactsPerBatch: OptionalSchema.when("sendInBatches", {
    is: 'true',
    then: RequiredSchema,
  }),
  intervalBetweenBatches: OptionalSchema.when("sendInBatches", {
    is: true,
    then: RequiredSchema,
  }),
  intervalIn: OptionalSchema.when("sendInBatches", {
    is: true,
    then: RequiredSchema,
  }),
});

export const AddLoadLayoutSchema = yup.object().shape({
  name: RequiredSchema,
});

export const AddTemplateSchema = yup.object().shape({
  name: RequiredSchema,
  description: RequiredSchema,
  currentFields: StringArrayRequiredSchema,
  hasTagsIncluded: yup.boolean().default(false),
});

export const BehaviorSchema = yup.object().shape({
  business: BusinessSchema,
});

export const BoteriaIntegrationsSchema = yup.object().shape({
  username: RequiredSchema,
  password: RequiredSchema,
  reCaptcha: RequiredSchema,
});

export const DialerIntegrationsSchema = yup.object().shape({
  login: RequiredSchema,
  password: RequiredSchema,
  projectURL: RequiredSchema,
  projectID: RequiredSchema,
  campaignID: RequiredSchema,
});

export const FTPIntegrationsSchema = yup.object().shape({
  active: yup.boolean().required(),
  host: OptionalSchema.when(["active"], {
    is: (active: boolean) => active === true,
    then: RequiredSchema,
  }),
  port: OptionalSchema.when(["active"], {
    is: (active: boolean) => active === true,
    then: RequiredSchema,
  }),
  username: OptionalSchema.when(["active"], {
    is: (active: boolean) => active === true,
    then: RequiredSchema,
  }),
  password: OptionalSchema.when(["active"], {
    is: (active: boolean) => active === true,
    then: RequiredSchema,
  }),  
  filePath: OptionalSchema.when(["active"], {
    is: (active: boolean) => active === true,
    then: RequiredSchema,
  }),
  fileName: OptionalSchema,
});

export const VtexIntegrationSchema = yup.object().shape({
  accountName: RequiredSchema,
  APIappKey: RequiredSchema,
  APIappToken: RequiredSchema,
  expireHours: RequiredSchema,
  journeyId: RequiredSchema,
});

export const ChangePasswordSchema = yup.object().shape({
  passwordHash: PasswordSchema,
  newPassword: PasswordSchema,
});

export const CompanySchame = yup.object().shape({
  fantasyName: RequiredSchema,
  cnpj: OptionalSchema,
  phoneNumber: PhoneSchema,
  email: EmailRequiredSchema,
  website: OptionalSchema,
  size: RequiredSchema,
  fusoName: RequiredSchema,
  fanpage: OptionalSchema,
  companyAddress: AddressSchema,
  responsible: RequiredSchema,
  responsibleEmail: RequiredSchema,
});

export const CreatePasswordSchema = yup.object().shape({
  password: PasswordSchema,
});

export const EmailChannelsSchema = yup.object().shape({
  username: OptionalSchema.when(["orchEmailProvider"], {
    is: (orchEmailProvider: string) => orchEmailProvider === "sendfy",
    then: RequiredSchema,
  }),
  password: OptionalSchema.when(["orchEmailProvider"], {
    is: (orchEmailProvider: string) => orchEmailProvider === "sendfy",
    then: RequiredSchema,
  }),
  from_email: EmailSchema.when(["orchEmailProvider"], {
    is: (orchEmailProvider: string) => orchEmailProvider === "sendfy",
    then: RequiredSchema,
  }),
  from_name: OptionalSchema.when(["orchEmailProvider"], {
    is: (orchEmailProvider: string) => orchEmailProvider === "sendfy",
    then: RequiredSchema,
  }),
  sendgrid: SendgridSchema,
});

export const ForgotEmailSchema = yup.object().shape({
  phone: PhoneSchema,
  reCaptcha: RequiredSchema,
});

export const ForgotPasswordSchema = yup.object().shape({
  email: EmailRequiredSchema,
  reCaptcha: RequiredSchema,
});

export const HSMChannelsSchema = yup.object().shape({
  username: RequiredSchema,
  password: RequiredSchema,
});

export const IdentifySchema = yup.object().shape({
  name: OptionalSchema,
  sector: OptionalSchema,
});

export const JourneyIdentitySchema = yup.object().shape({
  title: RequiredSchema,
  description: RequiredSchema,
  fusoName: OptionalSchema,
  sendingMode: RequiredSchema,
});

export const NewPasswordSchema = yup.object().shape({
  password: PasswordSchema,
  reCaptcha: RequiredSchema,
});

export const NewUserSchema = yup.object().shape({
  name: RequiredSchema,
  email: EmailRequiredSchema,
  phoneNumber: PhoneSchema,
  claim: RequiredSchema,
});

export const RegisterSchema = yup.object().shape({
  genre: OptionalSchema,
  phone: PhoneSchema,
  birthDate: RequiredSchema,
  userAddress: AddressSchema,
});

export const SignInSchema = yup.object().shape({
  email: EmailRequiredSchema,
  password: RequiredSchema,
  reCaptcha: RequiredSchema,
});

export const SignUpSchema = yup.object().shape({
  companyName: RequiredSchema,
  name: RequiredSchema,
  phone: PhoneSchema,
  email: EmailRequiredSchema,
  password: PasswordSchema,
  reCaptcha: RequiredSchema,
});

export const SMSChannelsSchema = yup.object().shape({
  campaign: RequiredSchema,
  username: RequiredSchema,
  password: RequiredSchema,
});

export const WelcomeSchema = yup.object().shape({
  company: RequiredSchema,
  organization: RequiredSchema,
});
