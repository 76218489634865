import { forwardRef, ReactNode } from "react";
import Loading from "../loading";
import { ButtonStyledType, Container, IconContainer } from "./styles";

type ButtonType = ButtonStyledType & {
  children?: ReactNode;
  loading?: boolean;
  icon?: ReactNode;
  active?: boolean;
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
};

const Button = forwardRef<HTMLButtonElement, ButtonType>(
  (
    {
      children,
      loading,
      icon,
      onClick,
      type = "button",
      positionIcon,
      active,
      disabled,
      ...args
    },
    ref
  ) => {
    return (
      <Container
        ref={ref}
        onClick={(e) => {
          e.stopPropagation();
          !disabled && onClick?.();
        }}
        type={type}
        disabled={disabled}
        active={active} // Passar a prop active
        {...args}
      >
        {loading ? (
          <Loading />
        ) : (
          <IconContainer positionIcon={positionIcon}>
            {icon !== null && icon}
            {children}
          </IconContainer>
        )}
      </Container>
    );
  }
);

export default Button;
