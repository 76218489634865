import useMap from "hooks/map";
import {
  Children,
  cloneElement,
  isValidElement,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { exitElement } from "utils";
import Option, { NoOptions, OptionType, ValueType } from "./option";
import {
  ArrowDownIcon,
  Container,
  InputFilter,
  OptionsContent,
  SelectContent,
  SelectStylesType,
} from "./styles";

type SelectType = Omit<SelectStylesType, "open"> & {
  children: ReactNode;
  placeholder?: string;
  defaultValue?: ValueType;
  value?: ValueType;
  $showSearch?: boolean;
  onChange?: (value: ValueType) => void;
};

const Select = ({
  children,
  placeholder,
  defaultValue = "",
  value,
  direction,
  $showSearch,
  onChange,
  disabled,
  ...args
}: SelectType) => {
  const [open, setOpen] = useState(false);
  const [internalValue, setInternalValue] = useState<ValueType>(defaultValue);
  const [texts, { set }] = useMap<ValueType, ReactNode>();
  const [filter, setFilter] = useState("");
  const selectRef = useRef<any>(null);
  const ref = useRef<HTMLDivElement>(null);
  const filterRef = useRef<HTMLInputElement>(null);

  // const optionsWidth = selectRef?.current?.clientWidth;
  const currentValue = useMemo(
    () => (value !== undefined ? value : internalValue),
    [value, internalValue]
  );

  const selectPlaceholder = useMemo(() => {
    if (!filter && currentValue === "") return placeholder;
  }, [filter, currentValue, placeholder]);

  useEffect(() => {
    if (open) filterRef.current?.focus();
    else filterRef.current?.blur();
  }, [open, filterRef]);

  useEffect(() => {
    const currentTexts = new Map();
    Children.forEach(children, (child) => {
      if (isValidElement<OptionType>(child)) {
        currentTexts.set(child.props?.value, child.props?.children);
      }
    });
    set(currentTexts);
  }, [children, set]);

  const handleToggle = useCallback((open: boolean) => {
    setOpen(open);
    setFilter("");
  }, []);

  const handleSelect = useCallback(
    (valueOption: ValueType) => {
      onChange?.(valueOption);
      value === undefined && setInternalValue(valueOption);
      setFilter("");
      setOpen(false);
    },
    [value, onChange]
  );

  useEffect(() => {
    const hide = exitElement(ref, () => handleToggle(false));
    document.documentElement.addEventListener("click", hide);
    return () => document.documentElement.removeEventListener("click", hide);
  }, [handleToggle]);

  return (
    <Container ref={ref} {...args}>
      <SelectContent
        ref={selectRef}
        disabled={disabled}
        onClick={() => !disabled && handleToggle(!open)}
      >
        <ArrowDownIcon open={open} />
        {!filter && currentValue !== "" && <p>{texts?.get(currentValue)}</p>}
        <InputFilter
          value={filter}
          ref={filterRef}
          readOnly={!$showSearch}
          placeholder={selectPlaceholder}
          onChange={(e) => setFilter(e.target.value)}
          onKeyUp={(e) => e.preventDefault()}
        />
      </SelectContent>
      <OptionsContent open={open} direction={direction}>
        {Children.count(children) ? (
          Children.map(children, (child) => {
            if (isValidElement<OptionType>(child)) {
              return cloneElement(child, { onClick: handleSelect });
            }
          })
        ) : (
          <NoOptions />
        )}
      </OptionsContent>
    </Container>
  );
};
export { Option };
export type { ValueType };
export default Select;
